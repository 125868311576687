"use client";

import React, { useState, useEffect} from 'react';
import ResolveModal from './ResolveModal';
import ViewLastSubmissionModal from './ViewLastSubmissionModal';
import { KYCRequirement, Document, TableDocument } from "./interfaces"
import { getFirmUser } from "../../APIs/WealthManagementAPI";

interface KYCRequirementCardProps {
    requirement: KYCRequirement;
    onResolve: () => void;
    onViewSubmission: () => void;
}

const KYCRequirementCard: React.FC<KYCRequirementCardProps> = ({ requirement, onResolve, onViewSubmission }) => {
    return (
        <div className="tw-border tw-rounded-lg tw-p-4 tw-mb-4 tw-shadow-sm">
            <div className="tw-flex-1">
                <h3 className="tw-text-lg tw-font-semibold">{requirement.title}</h3>
                <p className="tw-text-sm tw-text-gray-500">{requirement.description}</p>
                <p
                    className={`tw-text-sm tw-mt-2 ${requirement.status === 'pending' ? 'tw-text-red-500'
                        : requirement.status === 'in_review' ? 'tw-text-blue-500'
                            : 'tw-text-yellow-500'
                        }`}
                >
                    Status: {requirement.status === 'pending' ? 'Pending'
                        : requirement.status === 'in_review' ? 'In Review'
                            : 'Needs Action'}
                </p>

                {/* Feedback (only visible when status is 'needs_action') */}
                {requirement.status === 'needs_action' && requirement.feedback && (
                    <div className="tw-mt-3">
                        <h4 className="tw-font-semibold tw-text-sm tw-mb-2 tw-text-yellow-500">Feedback:</h4>
                        <ul className="tw-list-disc tw-list-inside tw-text-sm tw-text-gray-600">
                            {requirement.feedback.map((item: string[], index: number) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </div>
                )}

                {/* Button Logic */}
                <div className="tw-mt-3 tw-space-x-2">
                    {requirement.status === 'pending' && (
                        <button
                            className="tw-px-4 tw-py-2 tw-bg-red-500 tw-text-white tw-rounded-md hover:tw-bg-red-600"
                            onClick={onResolve}
                        >
                            Resolve
                        </button>
                    )}
                    <>
                    {requirement.status === 'in_review' && (
                        <button
                            className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md hover:tw-bg-blue-600"
                            onClick={onViewSubmission}
                        >
                            View Last Submission
                        </button>
                    )}</>
                    {requirement.status === 'needs_action' && (
                        <>
                            <button
                                className="tw-px-4 tw-py-2 tw-bg-red-500 tw-text-white tw-rounded-md hover:tw-bg-red-600"
                                onClick={onResolve}
                            >
                                Resolve
                            </button>
                            <button
                                className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md hover:tw-bg-blue-600"
                                onClick={onViewSubmission}
                            >
                                View Last Submission
                            </button>
                        </>
                    )}
                </div>
            </div></div>

    );
};

interface KYCRequirementListProps {
    requirements: KYCRequirement[];
    onResolve: (requirement: KYCRequirement) => void;
    onViewSubmission: (requirement: KYCRequirement) => void;
}

const KYCRequirementList: React.FC<KYCRequirementListProps> = ({ requirements, onResolve, onViewSubmission }) => {
    return (
        <div className="tw-space-y-4">
            {requirements.map((requirement, index) => (
                <KYCRequirementCard
                    key={index}
                    requirement={requirement}
                    onResolve={() => onResolve(requirement)}
                    onViewSubmission={() => onViewSubmission(requirement)}
                />
            ))}
        </div>
    );
};

const EnhancedKYC = ({ }) => {

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedRequirement, setSelectedRequirement] = useState<KYCRequirement | null>(null);
    const [viewSubModalOpen, setViewSubModalOpen] = useState(false);

    const [kycRequirements, setKycRequirements] = useState<KYCRequirement[]>([]);

    const onLoad = async () => {
        const firmUser = await getFirmUser();
        const requirements = firmUser.data.account?.requirements;
        requirements && setKycRequirements(requirements.map(
            (req: any) => ({
                id: req.id,
                title: req.type,
                description: req.description,
                status: req.status,
                feedback: req.feedback?.msg,
                submission: req.submission,
                availableDocs: firmUser.data.files
            })
        ));
    };

    useEffect(() => {
        onLoad();
    }, [modalOpen]);

    const handleResolve = (requirement: KYCRequirement) => {
        setSelectedRequirement(requirement);
        setModalOpen(true);
    };
    const handleCloseModal = () => {
        setSelectedRequirement(null);
        setModalOpen(false);
        setViewSubModalOpen(false);
        onLoad();
    };

    const handleViewSubmission = (requirement: KYCRequirement) => {
        setSelectedRequirement(requirement);
        setViewSubModalOpen(true);
    };

    return (
            <div className="tw-container tw-mx-auto tw-py-6 tw-p-5">
                <>{ (kycRequirements.length !==0 )?  <KYCRequirementList
                    requirements={kycRequirements}
                    onResolve={handleResolve}
                    onViewSubmission={handleViewSubmission}
                /> : <>No unfulfilled requirements.</> }</>
                <ResolveModal show={modalOpen} onClose={handleCloseModal} requirement={selectedRequirement} />
                <ViewLastSubmissionModal show={viewSubModalOpen} onClose={handleCloseModal} requirement={selectedRequirement} />
            </div>
    );
};

export default EnhancedKYC;