import { useState, useEffect, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import MuiButton from "../../Components/Buttons/MuiButton/MuiButton";
import MultiSelectFilter from "../../Components/MultiSelectFilter/MultiSelectFilter";
import ValueMultiselectFilter from "../../Components/ValueMultiselectFilter/ValueMultiselectFilter";
import Modal from "react-bootstrap/Modal";
import Box from "@mui/material/Box";
import {
  DataGridPro,
  useGridSelector,
  useGridApiRef,
  gridDetailPanelExpandedRowsContentCacheSelector,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
} from "@mui/x-data-grid-pro";
import Spinner1 from "../../Components/Spinners/Spinner1";
import SnackBar from "../../Components/Alerts/SnackBar/SnackBar";
import { UserContext } from "../../Contexts/UserContext.js";
import EnhancedKYC from "../../Components/EnhancedKYC/EnhancedKYC";

function Tasks() {
  const { user, setUser } = useContext(UserContext);
  const { client, fund } = useParams();
  const [resp, setResp] = useState();
  const [documentData, setDocumentData] = useState([]);
  const [loading, setLoading] = useState(false);
  const apiRef = useGridApiRef();
  const [selected, setSelected] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDocLink, setSelectedDocLink] = useState(null);
  const [magicLink, setMagicLink] = useState(null);
  const [approvalInProgress, setApprovalInProgress] = useState(false);
  const [clients, setClients] = useState([]);
  const [funds, setFunds] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [snackBar, setSnackBar] = useState(false);

  const [clientsSelected, setClientsSelected] = useState(
    client ? [decodeURI(client)] : []
  );
  const [fundsSelected, setFundsSelected] = useState(
    fund ? [decodeURI(fund)] : []
  );
  const [statusSelected, setStatusSelected] = useState([]);


  return (
    <>
                                <h2 className="tw-text-2xl tw-font-bold tw-mb-6">Tasks</h2>
    <EnhancedKYC />
    </>
  );
}
export default Tasks;
