import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PageLoading from "../Components/PageLoading/PageLoading";
import Header from "../Components/Header/Header";
import Login from "../Layouts/Advisor/Login/Login";
import ForgotPassword from "../Layouts/Advisor/Login/ForgotPassword";
import Signup from "../Layouts/Advisor/Signup/Signup";
import FundDetails from "../Layouts/Fund/FundDetails/FundDetails";
import UnauthenticatedRoute from "./UnauthenticatedRoute";
import AuthenticatedRoute from "./AuthenticatedRoute";
import MasterOpsAuthenticatedRoute from "./MasterOpsAuthenticatedRoute";
import VendorRoute from "./VendorRoute";
import ClientTable from "../Components/ClientTable/ClientTable.js";
import Support from "../Components/Support/Support.js";
import BYO from "../Components/Support/BYO.js";
import InvestPage from "../Components/InvestPage/InvestPage.js";
import AdvisorProfile from "../Components/AdvisorProfile/AdvisorProfile.js";
import FirmUsers from "../Components/FirmUsers/FirmUsers.js";
import AddClientWizard from "../Components/AddClientWizard/AddClientWizard.js";
import EnhancedKYC from "../Components/EnhancedKYC/EnhancedKYC";
import ResetPwd from "../Components/ResetPwd";

import InfiniteInputComponent from "../Components/InfiniteInputComponent/InfiniteInputComponent.js";
import InfiniteTabInput from "../Components/InfiniteInputComponent/InfiniteTabInput.js";
import Clients from "../Layouts/Advisor/Clients/Clients";
import ClientDetails from "../Layouts/Advisor/ClientDetails/ClientDetails";
import InvestFund from "../Layouts/Advisor/InvestFund/InvestFund";
import RecommendFund from "../Layouts/Advisor/RecommendFund/RecommendFund";
import AddFirmUser from "../Layouts/Advisor/AddFirmUser/AddFirmUser";
import GrantorInformation from "../Components/AddClientWizard/GrantorInformation.js";
import AggViewsMain from "../Layouts/AggViews/AggViewsMain.js";
import ManageFirm from "../Layouts/RootUser/ManageFirm/ManageFirm";
import TestDynamicSelectComponent from "../Layouts/RootUser/TestDynamicSelectComponent/TestDynamicSelectComponent";
import AddFirmClient from "../Layouts/Advisor/Clients/AddFirmClient/AddFirmClient";
import PageNotFound from "../Layouts/404/PageNotFound";
import SubDocs from "../Layouts/SubDocs/SubDocs";
import Portfolio from "../Layouts/Portfolio/Portfolio";
import RequestAllocationForm from "../Layouts/Advisor/RequestAllocationForm/RequestAllocationForm";
import MultiSelectTable from "../Layouts/MultiSelectTable/MultiSelectTable";
import ParentComponent from "../Layouts/MultiSelectTable/ParentComponent";
import FilterPage from "./Test/FilterPage/FilterPage";
import CollapsibleTable from "./Test/ExpandableTable/ExpandableTable";
import CapitalCalls from "../Layouts/Advisor/ClientDetails/DetailedInfo/CapitalCalls/CapitalCalls";
import Documents from "../Layouts/Advisor/Documents/Documents.js";
import Tasks from "../Layouts/Advisor/Tasks.js"

export default function NavRoutes({ isLoading, activePage, setActivePage }) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("Error");

  const [relatedAccounts, setRelatedAccounts] = useState([{ firstName: "A" }]);
  const [activeTab, setActiveTab] = useState("Tab Owner #1");

  const handleRemoveFields = (index) => {
    const values = [...relatedAccounts];
    values.splice(index, 1);
    setRelatedAccounts(values);
  };

  const addNewTab = () => {
    setRelatedAccounts([
      ...relatedAccounts,
      {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        addressLine1: "",
        addressLine2: "",
        city: "",
        state: "",
        country: "",
        postalCode: "",
        addressType: "",
        deliverableAddressLine1: "",
        deliverableAddressLine2: "",
        deliverableCity: "",
        deliverableState: "",
        deliverableCountry: "",
        deliverablePostalCode: "",
        deliverableAddressType: "",
        dateOfBirth: "",
        dateOfBirthError: "",
        idType: "",
        idNum: "",
        idNumError: "",
        domicileCountry: "",
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        phoneNumberError: "",
        addressLine1Error: "",
        addressLine2Error: "",
        cityError: "",
        stateError: "",
        countryError: "",
        postalCodeError: "",
        deliverableAddressLine1Error: "",
        deliverableAddressLine2Error: "",
        deliverableCityError: "",
        deliverableStateError: "",
        deliverableCountryError: "",
        deliverablePostalCodeError: "",
        primaryAddressDeliverable: "",
        domicileCountryError: "",
        deliverableSelectionError: "",
      },
    ]);
    setActiveTab("Tab #" + (relatedAccounts.length + 1).toString());
  };

  const isMobile = window.matchMedia(
    "only screen and (max-width: 900px)"
  ).matches;

  return !isLoading ? (
    !isMobile ? (
      <Router>
        <Header activePage={activePage} setActivePage={setActivePage} />
        <Routes>
          <Route path="/" element={<Navigate to="/login" />} />
          {/* <Route
          path="/signup"
          element={
            <UnauthenticatedRoute>
              <Signup />
            </UnauthenticatedRoute>
          }
        /> */}
          <Route path="/feeder-signup" element={<Signup feeder={true} />} />
          <Route
            path="/login"
            element={
              <UnauthenticatedRoute>
                <Login />
              </UnauthenticatedRoute>
            }
          />
          <Route
            path="/invest"
            element={
              <AuthenticatedRoute>
                <div className="container-fluid ">
                  <InvestPage />
                </div>
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/fund/:id"
            element={
              <AuthenticatedRoute>
                <FundDetails />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/profile"
            element={
              <AuthenticatedRoute>
                <AdvisorProfile />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/add-clients"
            element={
              <AuthenticatedRoute>
                <AddClientWizard />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/multi-select"
            element={
              <AuthenticatedRoute>
                <ParentComponent />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/infinite-input"
            element={
              <InfiniteInputComponent
                accountID={"test"}
                activeTab={0}
                inputFields={[{ firstName: "A" }]}
                setInputFields={(a, b, c) => { }}
                handleRemoveFields={() => { }}
                index={0}
                setActiveTab={() => { }}
                setActiveSlide={() => { }}
                setHideButton={() => { }}
                disabled={false}
                role={"Tab"}
              />
            }
          />
          <Route
            path="/test-infinite-input"
            element={
              <div className="container-fluid mt-5 pt-5">
                <GrantorInformation setActiveSlide={() => { }} />
              </div>
            }
          />
          <Route
            path="/fund/:id/request-allocation"
            element={
              <AuthenticatedRoute>
                <RequestAllocationForm />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/fund/:id/confirm-investment"
            element={
              <AuthenticatedRoute>
                <InvestFund />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/fund/:id/confirm-recommendation"
            element={
              <AuthenticatedRoute>
                <RecommendFund />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/clients"
            element={
              <VendorRoute>
                <Clients />
              </VendorRoute>
            }
          />
          <Route
            path="/clients/add-firm-client"
            element={
              <AuthenticatedRoute>
                <AddFirmClient />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/account/add"
            element={
              <AuthenticatedRoute>
                <AddFirmClient />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/clients/:id"
            element={
              <VendorRoute>
                <ClientDetails />
              </VendorRoute>
            }
          />
          <Route
          path="/tasks"
          element={
            <div className="container pt-5 mt-5">
            <div className="row g-0 w-100">
              <div className="col-12">
                <div className="row g-0 w-100 mb-4"></div>
                <div
                  className="p-4"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                  }}
                >
            <Tasks /></div></div></div></div>
          }
          />
          <Route
          path="/reset-pwd"
          element={
            <div className="container pt-5 mt-5">
            <div className="row g-0 w-100">
              <div className="col-12">
                <div className="row g-0 w-100 mb-4"></div>
                <div
                  className="p-4"
                  style={{
                    backgroundColor: "white",
                    borderRadius: "8px",
                  }}
                >
            <ResetPwd /></div></div></div></div>
          }
          />
          <Route
            path="/account"
            element={
              <VendorRoute>
                <ClientDetails account={true} />
              </VendorRoute>
            }
          />
          <Route
            path="/support"
            element={
              <AuthenticatedRoute>
                <Support />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/documents"
            element={
              <VendorRoute>
                <div className="container pt-5 mt-5">
                  <div className="row g-0 w-100">
                    <div className="col-12">
                      <div className="row g-0 w-100 mb-4"></div>
                      <div
                        className="p-4"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <Documents />
                      </div>
                    </div>
                  </div>
                </div>
              </VendorRoute>
            }
          />
          <Route
            path="/documents/:client/:fund"
            element={
              <VendorRoute>
                <div className="container pt-5 mt-5">
                  <div className="row g-0 w-100">
                    <div className="col-12">
                      <div className="row g-0 w-100 mb-4"></div>
                      <div
                        className="p-4"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <Documents />
                      </div>
                    </div>
                  </div>
                </div>
              </VendorRoute>
            }
          />
          <Route
            path="/capital-calls/client/:client/fund/:fund"
            element={
              <VendorRoute>
                <div className="container pt-5 mt-5">
                  <div className="row g-0 w-100">
                    <div className="col-12">
                      <div className="row g-0 w-100 mb-4"></div>
                      <div
                        className="p-4"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <CapitalCalls showClient={true} />
                      </div>
                    </div>
                  </div>
                </div>
              </VendorRoute>
            }
          />
          <Route
            path="/capital-calls/client/:client/fund/:fund"
            element={
              <VendorRoute>
                <div className="container pt-5 mt-5">
                  <div className="row g-0 w-100">
                    <div className="col-12">
                      <div className="row g-0 w-100 mb-4"></div>
                      <div
                        className="p-4"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <CapitalCalls showClient={true} />
                      </div>
                    </div>
                  </div>
                </div>
              </VendorRoute>
            }
          />
          <Route
            path="/capital-calls/fund/:fund"
            element={
              <VendorRoute>
                <div className="container pt-5 mt-5">
                  <div className="row g-0 w-100">
                    <div className="col-12">
                      <div className="row g-0 w-100 mb-4"></div>
                      <div
                        className="p-4"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <CapitalCalls showClient={true} />
                      </div>
                    </div>
                  </div>
                </div>
              </VendorRoute>
            }
          />
          <Route
            path="/capital-calls/fund/:fund"
            element={
              <AuthenticatedRoute>
                <div className="container pt-5 mt-5">
                  <div className="row g-0 w-100">
                    <div className="col-12">
                      <div className="row g-0 w-100 mb-4"></div>
                      <div
                        className="p-4"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <CapitalCalls showClient={true} />
                      </div>
                    </div>
                  </div>
                </div>
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/capital-calls"
            element={
              <VendorRoute>
                <div className="container pt-5 mt-5">
                  <div className="row g-0 w-100">
                    <div className="col-12">
                      <div className="row g-0 w-100 mb-4"></div>
                      <div
                        className="p-4"
                        style={{
                          backgroundColor: "white",
                          borderRadius: "8px",
                        }}
                      >
                        <CapitalCalls showClient={true} />
                      </div>
                    </div>
                  </div>
                </div>
              </VendorRoute>
            }
          />
          <Route
            path="/byo"
            element={
              <AuthenticatedRoute>
                <BYO />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/add-firm-user"
            element={
              <AuthenticatedRoute>
                <AddFirmUser />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/portfolio"
            element={
              <AuthenticatedRoute>
                <Portfolio />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/agg-views"
            element={
              <AuthenticatedRoute>
                <AggViewsMain />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/manage-firm"
            element={
              <MasterOpsAuthenticatedRoute>
                <ManageFirm />
              </MasterOpsAuthenticatedRoute>
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/test-component"
            element={
              <AuthenticatedRoute>
                <TestDynamicSelectComponent />{" "}
              </AuthenticatedRoute>
            }
          />
          <Route path="/subdocs/:code" element={<SubDocs />} />
          <Route path="/test-filter" element={<><br /><br /><br />
            <EnhancedKYC /></>} />
          <Route path="/test-table" element={<CollapsibleTable />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    ) : (
      <div className="container">
        <div className="row g-0 justify-content-center">
          <div className="col-12">
            <div className="row g-0 w-100 mt-4 justify-content-center">
              <h5>Sorry, this website is not optimized for mobile devices.</h5>
            </div>
            <div className="row g-0 w-100">
              <span>
                Please try accessing this site through a desktop computer.
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  ) : (
    <PageLoading />
  );
}
