import React, { useState, useEffect } from 'react';
import Autocomplete from 'react-google-autocomplete';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import TextInputField from "./InputFields/TextInputField/TextInputField";

interface AddressCallbacks {
  setAddressLine1: (value: string) => void;
  setAddressLine2: (value: string) => void;
  setCity: (value: string) => void;
  setState: (value: string) => void;
  setPostalCode: (value: string) => void;
  setCountry: (value: string) => void;
}

interface AddressFormProps {
  addressCallbacks: AddressCallbacks;
  object: string;
  errorList: string[];
  values: any;
  errorCallbacks: any;
  disabled: boolean | undefined;
}

const AddressForm: React.FC<AddressFormProps> = ({ values, addressCallbacks, object, errorList, errorCallbacks, disabled }) => {
  const [addressLine1, setAddressLine1] = useState(values.addressLine1 || '');
  const [addressLine2, setAddressLine2] = useState(values.addressLine2 || '');
  const [city, setCity] = useState(values.city || '');
  const [state, setState] = useState(values.state || '');
  const [postalCode, setPostalCode] = useState(values.postalCode || '');
  const [country, setCountry] = useState(values.country || '');

  const [showError, setShowError] = useState(false);


  useEffect(() => {
    errorCallbacks && errorCallbacks.map((e: any) => { e("") });
    if ([addressLine1, city, state, postalCode, country].includes("")) {
      setShowError(true);
    }
    else {
      setShowError(false);
    }
  }, [addressLine1, city, state, postalCode, country]);



  const handlePlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (!place.address_components) {
      return;
    }

    const addressComponents: { [key: string]: string } = {};
    place.address_components.forEach((component) => {
      console.log(component);
      const componentType = component.types[0];
      const componentValue = component.long_name;

      switch (componentType) {
        case 'street_number':
          addressComponents['street_number'] = componentValue;
          break;
        case 'route':
          addressComponents['route'] = componentValue;
          break;
        case 'administrative_area_level_2':
          addressComponents['city'] = componentValue;
          break;
        case 'locality':
          addressComponents['city'] = componentValue;
          break;
        case 'neighborhood':
          addressComponents['city'] = componentValue;
          break;
        case 'administrative_area_level_1':
          addressComponents['state'] = componentValue;
          break;
        case 'postal_code':
          addressComponents['postalCode'] = componentValue;
          break;
        case 'country':
          addressComponents['country'] = componentValue;
          break;
        default:
          break;
      }
    });

    const newAddressLine1 = `${addressComponents['street_number'] || ''} ${addressComponents['route'] || ''}`.trim();
    const newCity = addressComponents['city'] || '';
    const newState = addressComponents['state'] || '';
    const newPostalCode = addressComponents['postalCode'] || '';
    const newCountry = addressComponents['country'] || '';

    console.log(newAddressLine1);
    setAddressLine1(newAddressLine1);
    setCity(newCity);
    setState(newState);
    setPostalCode(newPostalCode);
    setCountry(newCountry);

    // Update the external state
    addressCallbacks.setAddressLine1(newAddressLine1);
    addressCallbacks.setCity(newCity);
    addressCallbacks.setState(newState);
    addressCallbacks.setPostalCode(newPostalCode);
    addressCallbacks.setCountry(newCountry);
  };

  return (
    <div className="tw-space-y-4" style={{ "paddingBottom": "20px" }}>
      <h2 className="tw-text-lg tw-font-bold">Address for {object}</h2>

      {/* Google Places Autocomplete Input */}
      {!disabled ?
        <>
          <Autocomplete
            apiKey="AIzaSyCPV-4nrhCPI84lc0AcmdkZgZkdjF_shRA"
            onPlaceSelected={handlePlaceSelect}
            options={{ "types": ['address'] }}
            className="tw-w-full tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-text-sm"
            placeholder="Search for an address"
          />

          {/* Error Message */}
          {showError && (
            <p className="tw-text-sm">All fields except second address line are required.</p>
          )}

          {/* Manual Address Fields */}
          <div className="tw-space-y-3">
            <input
              type="text"
              placeholder="Address Line 1"
              value={addressLine1}
              onChange={(e) => {
                setAddressLine1(e.target.value);
                addressCallbacks.setAddressLine1(e.target.value);
              }}
              className="tw-w-full tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-text-sm"
            />
            <input
              type="text"
              placeholder="Address Line 2"
              value={addressLine2}
              onChange={(e) => {
                setAddressLine2(e.target.value);
                addressCallbacks.setAddressLine2(e.target.value);
              }}
              className="tw-w-full tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-text-sm"
            />
            <div className="tw-flex tw-space-x-2">
            <input
              type="text"
              placeholder="City"
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
                addressCallbacks.setCity(e.target.value);
              }}
              className="tw-w-1/2 tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-text-sm"
            />

          <input
              type="text"
              placeholder="Postal Code"
              value={postalCode}
              onChange={(e) => {
                setPostalCode(e.target.value);
                addressCallbacks.setPostalCode(e.target.value);
              }}
              className="tw-w-1/2 tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-text-sm"
            /></div>
<div className="tw-flex tw-space-x-2">
            {/* Country and State Dropdowns */}
            <CountryDropdown
              value={country}
              onChange={(val) => {
                setCountry(val);
                addressCallbacks.setCountry(val);
              }}
              classes="tw-w-1/2 tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-text-sm"
            />
            <RegionDropdown
              country={country}
              value={state}
              onChange={(val) => {
                setState(val);
                addressCallbacks.setState(val);
              }}
              classes="tw-w-1/2 tw-border tw-border-gray-300 tw-rounded tw-p-2 tw-text-sm"
            /></div>

          </div>         </> : <>
          <TextInputField
            value={`${values.addressLine1} ${values.addressLine2 || ''} ${values.addressLine2} ${values.city} ${values.state} ${values.postalCode} ${values.country}`}
            setValue={() => { }
            }
            setValueError={() => {
            }}
            valueError={false}
            label={"Address"}
            mask={null}
            required={false}
            fullWidth={true}
            size={"small"}
            disabled={true}
            shrink={true}
            id={"account-name-input"}
          /></>}
    </div>
  );
};

export default AddressForm;
