import React, { useState, useContext } from "react";
import { UserContext } from "../../../../../Contexts/UserContext";
import "./Styles/Styles.scss";
import TextInputField from "../../../../../Components/InputFields/TextInputField/TextInputField";
import SelectInputField from "../../../../../Components/InputFields/SelectInputField/SelectInputField";
import PhoneInputField from "../../../../../Components/InputFields/PhoneInputField/PhoneInputField";
import { stateList } from "../../../../../Components/stateList";
import { countryList } from "../../../../../Components/countryList";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import { isValidPhoneNumber } from "../../../../../utilities/functions/functions";
import { isEmailValid } from "../../../../../utilities/functions/functions";
import SnackBar from "../../../../../Components/Alerts/SnackBar/SnackBar";
import { stripOutDigits } from "../../../../../utilities/functions/functions";
import { addFirmUser } from "../../../../../APIs/FundActions";

function AddFirmUser({ setShowAddFirmUser }) {
  const { user } = useContext(UserContext);
  const shareClients = user.firmInfo.shareClients;
  const firmType = user.firmInfo.firmType;
  const [formVals, setFormVals] = useState({
    firstName: "",
    firstNameError: "",
    lastName: "",
    lastNameError: "",
    role: "",
    roleError: "",
    phoneNumber: "",
    phoneNumberError: "",
    country: "",
    countryError: "",
    email: "",
    emailError: "",
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const dropdownOptions = shareClients
    ? [
        { value: "Ops", label: "Ops" },
        { value: "Relationship Manager", label: "Relationship Manager" },
      ]
    : [
        { value: "Ops", label: "Ops" },
        { value: "Advisor", label: "Advisor" },
        ... firmType === "SS" ? [{ value: "Self-Serve", label: "Self-Serve" }] : []
      ];

  const setForm = (key, newVal) => {
    setFormVals((prevState) => ({ ...prevState, [key]: newVal }));
  };

  const submit = async () => {
    var errors = false;

    if (formVals["firstName"] === "") {
      errors = true;
      setForm("firstNameError", "This field is required.");
    }

    if (formVals["lastName"] === "") {
      errors = true;
      setForm("lastNameError", "This field is required.");
    }

    if (formVals["role"] === "") {
      errors = true;
      setForm("roleError", "This field is required.");
    }

    if (formVals["email"] === "") {
      errors = true;
      setForm("emailError", "This field is required.");
    } else if (!isEmailValid(formVals["email"])) {
      errors = true;
      setForm("emailError", "Please enter a valid email.");
    }

    if (formVals["country"] === "") {
      errors = true;
      setForm("countryError", "This field is required.");
    }

    if (formVals["phoneNumber"] === "") {
      errors = true;
      setForm("phoneNumberError", "This field is required.");
    } else if (!isValidPhoneNumber(formVals["phoneNumber"])) {
      errors = true;
      setForm("phoneNumberError", "Please enter a valid phone number.");
    }

    if (errors) {
      return;
    }

    try {
      const resp = await addFirmUser({
        firm_id: user.firmInfo.id,
        first_name: formVals.firstName.trim(),
        last_name: formVals.lastName.trim(),
        role: formVals.role.trim(),
        email: formVals.email.trim(),
        phone_number: stripOutDigits(formVals.phoneNumber),
        country: formVals.country.trim(),
      });

      setSuccess(true);
      setShowAddFirmUser(false);
    } catch (e) {
      setError(true);
      return;
    }
  };

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-4">
        <div className="d-flex align-items-center">
          <img
            src="https://d1swyfveypj49y.cloudfront.net/NewBackArrow.svg"
            style={{
              width: "14px",
              height: "14px",
              cursor: "pointer",
              marginRight: "12px",
            }}
            onClick={() => setShowAddFirmUser(false)}
          />
          <h5 className="mt-auto mb-auto">Add User</h5>
        </div>
      </div>
      <div class="row g-0 w-100 add-account-wizard-container">
        <div class="col-12">
          <React.Fragment>
            <React.Fragment>
              <div className="row g-0 w-100 mb-4">
                <div className="col pe-2">
                  <TextInputField
                    value={formVals.firstName}
                    setValue={(newVal) => setForm("firstName", newVal)}
                    valueError={formVals.firstNameError}
                    setValueError={(newVal) =>
                      setForm("firstNameError", newVal)
                    }
                    label={"First Name"}
                    required={true}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"individual-account-address-line-1-input"}
                  />
                </div>
                <div className="col pe-0 ps-2">
                  <TextInputField
                    value={formVals.lastName}
                    setValue={(newVal) => setForm("lastName", newVal)}
                    valueError={formVals.lastNameError}
                    setValueError={(newVal) => setForm("lastNameError", newVal)}
                    label={"Last Name"}
                    required={false}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"individual-account-address-line-2-input"}
                  />
                </div>
              </div>
              <div className="row g-0 w-100 mb-4">
                <div className="col pe-2">
                  <SelectInputField
                    setValue={(newVal) => setForm("role", newVal)}
                    value={formVals.role}
                    valueError={formVals.roleError}
                    setValueError={(newVal) => setForm("roleError", newVal)}
                    label={"Role"}
                    selectOptions={dropdownOptions}
                    required={true}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"domicile-country-input"}
                  />
                </div>
                <div className="col pe-0 ps-2">
                  <TextInputField
                    value={formVals.email}
                    setValue={(newVal) => setForm("email", newVal)}
                    valueError={formVals.emailError}
                    setValueError={(newVal) => setForm("emailError", newVal)}
                    label={"Email"}
                    required={true}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"individual-account-address-line-1-input"}
                  />
                </div>
              </div>

              <div className="row g-0 w-100 mb-4">
                <div className="col pe-2">
                  <SelectInputField
                    setValue={(newVal) => setForm("country", newVal)}
                    value={formVals.country}
                    valueError={formVals.countryError}
                    setValueError={(newVal) => setForm("countryError", newVal)}
                    label={"Country"}
                    selectOptions={countryList}
                    required={true}
                    fullWidth={true}
                    shrink={true}
                    size={"small"}
                    id={"domicile-country-input"}
                  />
                </div>
                <div className="col pe-0 ps-2">
                  <PhoneInputField
                    shrink={true}
                    label={"Phone Number"}
                    size={"small"}
                    fullWidth={true}
                    required={true}
                    value={formVals.phoneNumber}
                    setValue={(newVal) => {
                      setForm("phoneNumber", newVal);
                      setForm("phoneNumberError", null);
                    }}
                    valueError={formVals.phoneNumberError}
                    setValueError={(newVal) =>
                      setForm("phoneNumberError", newVal)
                    }
                    disabled={false}
                  />
                  {formVals.phoneNumberError && (
                    <div className="SignupForm-field-error-text mt-1">
                      <span class="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained Mui-required css-k4qjio-MuiFormHelperText-root">
                        {formVals.phoneNumberError}
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          </React.Fragment>

          <MuiButton buttonText={"Submit"} onClick={submit} />
        </div>
      </div>
      <SnackBar
        snackbarState={error}
        setSnackbarState={setError}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"error"}
        message={"Something went wrong."}
      />
      <SnackBar
        snackbarState={success}
        setSnackbarState={setSuccess}
        vertical={"top"}
        horizontal={"right"}
        duration={5000}
        sxProps={{ paddingLeft: "3px" }}
        severity={"success"}
        message={"User successfully added."}
      />
    </React.Fragment>
  );
}
export default AddFirmUser;
