"use client";

import React, { useState, useEffect } from 'react';
import { KYCRequirement, Document, TableDocument } from './interfaces';
import { getFirmUser } from "../../APIs/WealthManagementAPI";
import axios from "axios";
import { getRequestHeaders } from "../../utilities/functions/functions";
import { HOST } from "../../utilities/host-config/host-config.js";
import Spinner1 from "../Spinners/Spinner1";

interface ResolveModalProps {
  show: boolean;
  onClose: () => void;
  requirement: KYCRequirement | null;
}

const ResolveModal: React.FC<ResolveModalProps> = ({ show, onClose, requirement }) => {
  const [documents, setDocuments] = useState<TableDocument[]>([]);
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [notSubmitted, setNotSubmitted] = useState<boolean>(true);

  const [currentPage, setCurrentPage] = useState<number>(1);

  // Pagination
  const rowsPerPage = 5;
  
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;  
  const totalPages = Math.ceil(documents.length / rowsPerPage) + 1;


  useEffect(() => {
    if (show) {
      setError(false);
    }
  }, [documents]);

  useEffect(() => {
    if (show) {
      requirement && setDocuments(requirement.availableDocs);
      if (requirement && requirement.submission.length > 0) {
        const lastSubmission = requirement.submission[requirement.submission.length - 1];
        setDocuments(lastSubmission.user_files.map((doc: Document) => ({ ...doc, checked: true }))); // Pre-check documents
      }
    }
  }, [requirement]);

  if (!show || !requirement) return null;

  const submitDocs = async function (payload: any) {
    const config = await getRequestHeaders();
    const resp = await axios.post(HOST + "/verification/submission", payload, config).then(
      () => { setError(false); setLoading(false); setNotSubmitted(false); }
    ).catch(
      e => {
        setLoading(false);
        setError(true);
      }
    );
  }


  const deleteDoc = async function (id: any) {
    setLoading(true);
    const config = await getRequestHeaders();
    const resp = await axios.post(HOST + "/verification/file/delete", { "file": id }, config).then(
      () => { setLoading(false); setDocuments(requirement.availableDocs.filter((doc: TableDocument) => doc.id !== id)); }
    ).catch(
      e => {
        setLoading(false);
        //setError(true);
      }
    );
    setLoading(false);
  }


  // Handle file upload
  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const b64 = reader && reader.result && reader.result.toString().split(",")[1];
        const newDocument: TableDocument = {
          id: documents.length + 1,
          name: event.target.files ? event.target.files[0].name : '',
          upload_date: new Date().toLocaleDateString(),
          checked: true,
          file: b64 ? b64 : ''
        };
        setDocuments([newDocument, ...documents]); // Add new doc to the top
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleCheckboxChange = (id: number) => {
    setDocuments(
      documents.map((doc) =>
        doc.id === id ? { ...doc, checked: !doc.checked } : doc
      )
    );
  };


  const ErrorBar: React.FC<{}> = ({ }) => {
    return (
      <div className="tw-bg-red-100 tw-border tw-border-red-400 tw-text-red-700 tw-px-4 tw-py-3 tw-rounded tw-relative" role="alert">
        <strong className="font-bold">Error</strong>
        <span className="tw-block tw-sm:inline">Must select at least one file.</span>
      </div>);
  }

  return (
    <div>
      <div className="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75 tw-flex tw-items-center tw-justify-center">
        <div className="tw-bg-white tw-rounded-lg tw-p-6 tw-w-full tw-max-w-lg">
          {!loading ? <>
            <h2 className="tw-text-xl tw-font-bold tw-mb-4">Resolve {requirement.title}</h2>
            {/* Body*/}
            {notSubmitted ? <>
              {error && <ErrorBar />}

              <p className="tw-text-sm tw-text-gray-600 tw-mb-4">{requirement.description}</p>

              {/* File Upload Button */}
              <label className="tw-block tw-mb-4">
                <span className="tw-sr-only">Choose File</span>
                <input
                  type="file"
                  className="tw-block tw-w-full tw-text-sm tw-text-gray-500 tw-file:mr-4 tw-file:py-2 tw-file:px-4 tw-file:rounded-full tw-file:border-0 tw-file:text-sm tw-file:font-semibold tw-file:bg-blue-50 tw-file:text-blue-700 hover:tw-file:bg-blue-100"
                  onChange={handleFileUpload}
                />
              </label>

              {/* Available Documents Section */}
              <h3 className="tw-text-lg tw-font-semibold tw-mb-2">Uploaded Documents</h3>
              <p className="tw-text-sm tw-text-gray-600 tw-mb-4">Check all documents to include in submission.</p>

              {/* Documents Table */}
              <div className="tw-overflow-x-auto" style={{ "height": "250px" }}>
                <table className="tw-min-w-full tw-bg-white tw-border">
                  <thead>
                    <tr className="tw-w-full tw-bg-gray-100 tw-border-b">
                      <th className="tw-p-2 tw-text-left">
                        <input type="checkbox" disabled />
                      </th>
                      <th className="tw-p-2 tw-text-left">Name</th>
                      <th className="tw-p-2 tw-text-left">Upload Date</th>
                      <th className="tw-p-2 tw-text-left">View</th>
                      <th className="tw-p-2 tw-text-left">Delete</th>
                    </tr>
                  </thead> 
                  <tbody>
                    {documents.slice(startIndex, endIndex).map((doc) => (
                      <tr key={doc.id} className="tw-border-b">
                        <td className="tw-p-2">
                          <input
                            type="checkbox"
                            checked={doc.checked}
                            onChange={() => handleCheckboxChange(doc.id)}
                          />
                        </td>
                        <td className="tw-p-2">{doc.name}</td>
                        <td className="tw-p-2">{!!isNaN(Number(doc.id)) ? doc.upload_date : 'Not yet uploaded.'}</td>
                        <td className="tw-p-2">
                          {!!isNaN(Number(doc.id)) && <a href={doc.link} className="tw-text-blue-500 hover:tw-underline">View</a>}
                        </td>
                        <td className="tw-p-2">
                          {!!isNaN(Number(doc.id)) && <a onClick={() => deleteDoc(doc.id)} className="tw-text-blue-500 hover:tw-underline">Delete</a>}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
      
              </div>
              <div className="tw-flex tw-justify-center tw-mt-4">
                <button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="tw-bg-gray-300 tw-hover:bg-gray-400 tw-text-gray-800 tw-font-bold tw-py-2 tw-px-4 tw-rounded-l"
                >
                    Previous
                </button>
                <span className="mx-2 py-2">Page {currentPage} of {totalPages}</span>
                <button
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className="tw-bg-gray-300 tw-hover:bg-gray-400 tw-text-gray-800 tw-font-bold tw-py-2 tw-px-4 tw-rounded-r"
                >
                    Next
                </button>
            </div>

              
              </> : <>Submission successful.</>}

            {/* Close and Submit Buttons */}
            <div className="tw-mt-6 tw-flex tw-justify-end tw-space-x-2">
              <button
                className="tw-px-4 tw-py-2 tw-bg-gray-500 tw-text-white tw-font-medium tw-rounded-md"
                onClick={() => { setNotSubmitted(true); setError(false); setLoading(false); onClose(); }}
              >
                Close
              </button>
              {notSubmitted && <button
                className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-font-medium tw-rounded-md"
                onClick={async () => {
                  setLoading(true);
                  const payload = {
                    'files': documents.filter((doc: any) => doc.checked),
                    'requirement': requirement.id
                  }
                  await submitDocs(payload);
                }}
              >
                Submit
              </button>}
            </div> </> : <center><Spinner1 /></center>}
        </div>
      </div>
    </div>
  );
};

export default ResolveModal;