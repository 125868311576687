import React, { useState, useEffect, useContext } from "react";
import { json, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../../Contexts/UserContext.js";
import {
  isEmailValid,
  isPasswordValid,
} from "../../../utilities/functions/functions.js";
import { Auth } from "aws-amplify";
// import {
//   getAccountList,
//   getInvestorSummary,
//   updateIdentityId,
//   getInvestorProfile,
// } from "../../../Api/ProfileApi.js";
import {
  getAccountList,
  getInvestorSummary,
  updateIdentityId,
  getInvestorProfile,
} from "../../../APIs/ProfileAPI.js";
import MuiButton from "../../../Components/Buttons/MuiButton/MuiButton.js";
import PasswordInputField from "../../../Components/InputFields/PasswordInputField/PasswordInputField.js";
import TextInputField from "../../../Components/InputFields/TextInputField/TextInputField.js";
import SnackBar from "../../../Components/Alerts/SnackBar/SnackBar.js";
import "./Styles/Styles.scss";
import {
  getFirmUser,
  getSubdomainLogoLink,
  isFeederAPI,
} from "../../../APIs/WealthManagementAPI.js";
import { getSignedURL } from "../../../utilities/functions/functions.js";
import { H } from "highlight.run";
import FS from "@fullstory/browser";
import { FullStoryAPI } from "react-fullstory";

function Login(props) {
  //   let history = useHistory();
  let navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [loginError, setLoginError] = useState("");

  const [pwdReset, setPwdReset] = useState(false);

  const [newPwd, setNewPwd] = useState("");
  const [confirmNewPwd, setConfirmNewPwd] = useState("");
  const [confirmNewPwdError, setConfirmNewPwdError] = useState("");
  const [newPwdError, setNewPwdError] = useState("");

  const [loading, setLoading] = useState(false);
  const [cogUser, setCogUser] = useState(null);
  const { user, setUser } = useContext(UserContext);
  const [firmLogoLink, setFirmLogoLink] = useState("");
  const [firmLogoLoading, setFirmLogoLoading] = useState(true);

  const [accountCreationSuccessful, setAccountCreationSuccessful] = useState(
    location !== null &&
      location !== undefined &&
      location.state !== null &&
      location.state !== undefined &&
      location.state.accountCreationSuccessful !== null &&
      location.state.accountCreationSuccessful !== undefined
      ? location.state.accountCreationSuccessful
      : false
  );

  const [passwordResetSuccessful, setPasswordResetSuccessful] = useState(
    location !== null &&
      location !== undefined &&
      (location.state !== null) & (location.state !== undefined) &&
      location.state.passwordResetSuccessful !== null &&
      location.state.passwordResetSuccessful !== undefined
      ? location.state.passwordResetSuccessful
      : false
  );

  const [emailConfirmationSuccessful, setEmailConfirmationSuccessful] =
    useState(
      location !== null &&
        location !== undefined &&
        location.state !== null &&
        location.state !== undefined &&
        location.state.emailConfirmationSuccessful !== null &&
        location.state.emailConfirmationSuccessful !== undefined
        ? location.state.emailConfirmationSuccessful
        : false
    );

  const [errorSnackBarState, setErrorSnackbarState] = useState(false);

  const redirect = "";

  useEffect(() => {
    setLoginError("");
  }, [email, password]);

  useEffect(() => {
    async function isFeeder() {
      let feederResponse = await isFeederAPI();
      if (feederResponse.data.msg === true) {
        navigate("/feeder");
      }
    }
  });

  useEffect(() => {}, [redirect]);

  useEffect(() => {
    let getSubdomainLogo = async () => {
      let feederResponse = await isFeederAPI();
      if (feederResponse.data.msg === true) {
        setFirmLogoLink(
          "https://d1swyfveypj49y.cloudfront.net/AquaPlatformHeaderLogo.svg"
        );
      } else {
        let subdomainResponse = await getSubdomainLogoLink();

        try {
          var logoLink = await getSignedURL(
            "public",
            subdomainResponse.data.msg.logo
          );

          setFirmLogoLink(logoLink);
        } catch (err) {
          setFirmLogoLink(
            "https://d1swyfveypj49y.cloudfront.net/AquaPlatformHeaderLogo.svg"
          );
        }
      }
    };
    getSubdomainLogo();
  }, []);

  const resetAndSignIn = () => {
    var errors = false;

    if (newPwd === "") {
      errors = true;
      setNewPwdError("Please enter a password.");
    } else if (!isPasswordValid(newPwd)) {
      errors = true;
      setNewPwdError(
        "Your password must be longer than 10 characters and contain at least one number, an uppercase, a lowercase, and a special character. Valid special characters include (@,#,$,%,^,&,+,=,!,_)/"
      );
    }

    if (confirmNewPwd === "") {
      errors = true;
      setConfirmNewPwdError("Please confirm your password.");
    } else if (confirmNewPwd !== newPwd) {
      errors = true;
      setConfirmNewPwdError("Your passwords do not match.");
    }

    if (!isPasswordValid(newPwd)) {
      errors = true;
    }
    if (errors === false) {
      respondAuthChallenge();
      navigate("/invest");
    }
  };
  const respondAuthChallenge = () => {
    Auth.completeNewPassword(cogUser, newPwd, []).then((user) => {});
  };

  useEffect(() => {
    setEmailError("");
  }, [email]);

  useEffect(() => {
    setPasswordError("");
  }, [password]);

  useEffect(() => {
    setConfirmNewPwdError("");
  }, [confirmNewPwd]);

  useEffect(() => {
    setNewPwdError("");
  }, [newPwd]);

  const signIn = async () => {
    let isDataValid = true;

    if (email.length === 0) {
      setEmailError("Please enter an email");
      isDataValid = false;
    } else if (!isEmailValid(email)) {
      setEmailError("Please enter a valid email");
      isDataValid = false;
    }

    if (password.length === 0) {
      setPasswordError("Please enter a password");
      isDataValid = false;
    }

    if (!isDataValid) {
      return;
    }
    setLoading(true);
    try {
      const logUser = await Auth.signIn(
        email.toString().toLowerCase(),
        password
      );
      if (logUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        setPwdReset(true);
        setCogUser(logUser);
      }
      try {
        const identityId = await Auth.currentCredentials();
        //To-Do: Add this back in when backend functionality is added.
        // await updateIdentityId(identityId.identityId);

        try {
          const userID = email.toString().toLowerCase();
          // const userProfile = await getInvestorProfile(); // profile-pii
          // const investorSummary = await getInvestorSummary();
          // const accountList = await getAccountList();

          const wmFirmUserInfo = await getFirmUser();

          const isAuthenticated = true;

          async function getSignedLogoLink(
            id,
            authenticated,
            firmUser,
            setFirmUser,
            setLoadingVal
          ) {
            try {
              var logoLink = await getSignedURL(
                "public",
                firmUser.data.firm.logo
              );

              setFirmUser({
                id: id,
                userProfileData: {
                  id: firmUser.data.id,
                  firstName: firmUser.data.first_name,
                  lastName: firmUser.data.last_name,
                  email: firmUser.data.email,
                  phoneNumber: firmUser.data.phone_number,
                  country: firmUser.data.country,
                },
                userRole: firmUser.data.role,
                firmInfo: {
                  id: firmUser.data.firm.id,
                  firmType: firmUser.data.firm.firm_type,
                  name: firmUser.data.firm.name,
                  logoLink: firmUser ? (logoLink ? logoLink : "") : "",
                  shareClients: firmUser
                    ? firmUser.data.firm.share_clients
                    : false,
                },
                isAuthenticated: authenticated,
                clientId: firmUser.data.account ? firmUser.data.account.client_id : null
              });
              FullStoryAPI("identify", firmUser.data.email, {
                displayName:
                  firmUser.data.first_name + " " + firmUser.data.last_name,
                email: firmUser.data.email,
              });
              // FS.identify(firmUser.data.email, {
              //   displayName:
              //     firmUser.data.first_name + " " + firmUser.data.last_name,
              //   email: firmUser.data.email,
              //   // Add your own custom user variables here, details at
              //   // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Capturing-custom-user-properties
              // });
              setLoadingVal(false);
              (firmUser.data.role !== 'Vendor' ) ? navigate("/invest") : navigate("/clients");
            } catch (err) {
              setLoadingVal(false);
            }
          }

          getSignedLogoLink(
            userID,
            isAuthenticated,
            wmFirmUserInfo,
            setUser,
            setLoading
          );

          // setUser({
          //   id: userID,
          //   userProfileData: {
          //     id: wmFirmUserInfo.data.id,
          //     firstName: wmFirmUserInfo.data.first_name,
          //     lastName: wmFirmUserInfo.data.last_name,
          //     email: wmFirmUserInfo.data.email,
          //     phoneNumber: wmFirmUserInfo.data.phone_number,
          //     country: wmFirmUserInfo.data.country,
          //   },
          //   userRole: wmFirmUserInfo.data.role,
          //   firmInfo: {
          //     id: wmFirmUserInfo.data.firm.id,
          //     name: wmFirmUserInfo.data.firm.name,
          //   },
          //   isAuthenticated: isAuthenticated,
          // });

          // setLoading(false);
          // }
        } catch (error) {
          if (process.env.DEBUG) console.log("There is an error.");
          if (process.env.DEBUG) console.error(error);
          setLoading(false);
          setUser(null);
        }
      } catch (error) {
        setLoading(false);
        if (process.env.DEBUG) console.error(error);
      }
    } catch (err) {
      if (process.env.DEBUG) console.error(err);
      if (
        err &&
        err.code !== null &&
        err.code !== undefined &&
        err.code === "UserNotConfirmedException"
      ) {
        try {
          await Auth.resendSignUp(email);
          navigate("/verify-email", { state: { email: email } });
          //   history.push({
          //     pathname: "/verify-email",
          //     state: { email: email },
          //   });
        } catch (err) {
          setLoading(false);
          setErrorSnackbarState(true);
        }
      } else if (
        err &&
        err.code !== null &&
        err.code !== undefined &&
        err.code === "NotAuthorizedException"
      ) {
        setPasswordError("Incorrect username or password");
      } else if (err && err.code !== null && err.code !== undefined) {
        setLoginError("Error signing in.");
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
        signIn();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [email, password]);

  const firmLogoOnLoad = () => {
    setFirmLogoLoading(false);
  };

  return (
    <div className="container-fluid w-100 g-0 ms-0 me-0 overflow-hidden cover-background align-items-center justify-content-center d-flex">
      <div className="row g-0 w-100 align-items-center justify-content-center">
        <div className="col-6 h-100 d-none d-sm-none d-md-none d-lg-block cover">
          <img
            src="https://d1swyfveypj49y.cloudfront.net/AquaNewLoginImage.svg"
            style={{
              height: "100vh",
              backgroundSize: "cover !important",
              zIndex: 2,
            }}
            alt="community"
          ></img>
        </div>
        <div className="col-xxl-6 col-xl-6 col-lg-6 col-10 mt-auto mb-auto align-items-center foreground-content">
          <div className="container-fluid g-0 ms-5 pe-3 h-100 align-items-center mt-auto mb-auto pt-auto pb-auto">
            <div className="row g-0 w-100 align-items-center h-100 mt-auto mb-auto pt-auto pb-auto">
              <div className="col-12 align-items-center mt-auto mb-auto pt-auto pb-auto">
                {!pwdReset && (
                  <>
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <div className="container-fluid">
                          <div className="d-flex align-items-center">
                            <div style={{ display: "inline-block" }}>
                              <img
                                src={firmLogoLink}
                                height="36"
                                style={{
                                  "height": "36px",
                                  display: firmLogoLoading
                                    ? "none"
                                    : "inline-block",
                                }}
                                onLoad={firmLogoOnLoad}
                              />
                              <div
                                className="animated-slow"
                                height="36"
                                style={{
                                  height: "36px",
                                  width: "100px",
                                  borderRadius: "10px",
                                  display: firmLogoLoading
                                    ? "inline-block"
                                    : "none",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <TextInputField
                          value={email}
                          setValue={setEmail}
                          valueError={emailError}
                          setValueError={setEmailError}
                          label={"Email"}
                          required={true}
                          fullWidth={true}
                          shrink={true}
                          size={"small"}
                          id={"email-input"}
                        />
                      </div>
                    </div>
                    <div className="row g-0 w-100 mb-2">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <PasswordInputField
                          value={password}
                          setValue={setPassword}
                          valueError={passwordError}
                          setValueError={setPasswordError}
                          label={"Password"}
                          required={true}
                          fullWidth={true}
                          shrink={true}
                          size={"small"}
                          id={"password-input"}
                        />
                      </div>
                    </div>{" "}
                    <div class="row g-0 w-100 mb-2">
                      <span
                        className="forgotPassword"
                        style={{
                          cursor: "pointer",
                          textAlign: "right",
                          width: "50%",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          navigate("/forgot-password");
                        }}
                      >
                        Forgot password
                      </span>
                    </div>
                    <div className="row g-0 w-100">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <div className="row g-0 w-100">
                          <div className="col ms-0 me-auto">
                            <div className="d-flex">
                              <MuiButton
                                buttonText={"Sign In"}
                                onClick={signIn}
                                size={"large"}
                                loading={loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {pwdReset && (
                  <>
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <span className="sized-down-enter-text">
                          Reset password here
                        </span>
                      </div>
                    </div>
                    <div className="row g-0 w-100 mb-4">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <PasswordInputField
                          value={newPwd}
                          setValue={setNewPwd}
                          valueError={newPwdError}
                          setValueError={setNewPwdError}
                          label={"Password"}
                          required={true}
                          fullWidth={true}
                          shrink={true}
                          size={"small"}
                          id={"password-input"}
                        />
                      </div>
                    </div>
                    <div className="row g-0 w-100 mb-2">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <PasswordInputField
                          value={confirmNewPwd}
                          setValue={setConfirmNewPwd}
                          valueError={confirmNewPwdError}
                          setValueError={setConfirmNewPwdError}
                          label={"Confirm Password"}
                          required={true}
                          fullWidth={true}
                          shrink={true}
                          size={"small"}
                          id={"password-input"}
                        />
                      </div>
                    </div>{" "}
                    <div className="row g-0 w-100">
                      <div className="col-xxl-6 col-xl-6 col-lg-7 col-11">
                        <div className="row g-0 w-100">
                          <div className="col ms-0 me-auto">
                            <div className="d-flex">
                              <MuiButton
                                buttonText={"Reset and sign in"}
                                onClick={resetAndSignIn}
                                size={"large"}
                                loading={loading}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <SnackBar
                  snackbarState={emailConfirmationSuccessful}
                  setSnackbarState={setEmailConfirmationSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={
                    "Your account was successfully confirmed. You may now log in."
                  }
                />
                <SnackBar
                  snackbarState={errorSnackBarState}
                  setSnackbarState={setErrorSnackbarState}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={10000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"error"}
                  message={
                    "Unfortunately, you will be unable to login because you have not confirmed your email. We tried resending a confirmation code to " +
                    email +
                    ", but we were unable to. Please confirm that you have signed up using a valid email that you still have access to."
                  }
                />
                <SnackBar
                  snackbarState={loginError}
                  setSnackbarState={setLoginError}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"error"}
                  message={"Sorry, there was an error logging in."}
                />
                <SnackBar
                  snackbarState={passwordResetSuccessful}
                  setSnackbarState={setPasswordResetSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={"Password reset successfully."}
                />
                <SnackBar
                  snackbarState={accountCreationSuccessful}
                  setSnackbarState={setAccountCreationSuccessful}
                  vertical={"top"}
                  horizontal={"right"}
                  duration={5000}
                  sxProps={{ paddingLeft: "3px" }}
                  severity={"success"}
                  message={
                    "Your account was successfully created. You may now log in."
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
