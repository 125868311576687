import React, { useState, useEffect } from "react";
import { getWMFirmInfo } from "../APIs/FirmInformationAPI";

export const GetWMFirmInfo = (setLoading, refresh) => {
  const [wmFirmInfo, setWmFirmInfo] = useState(null);

  useEffect(() => {
    async function getWMFirmInformation() {
      setLoading(true);
      try {
        const response = await getWMFirmInfo();

        const wmFirmData = response.data;

        if (wmFirmData !== null && wmFirmData !== undefined) {
          let firmTypeMapping = {
            RIA: "Registered Investment Advisor",
            BD: "Broker Dealer",
          };

          let wmFirmObject = {
            id: wmFirmData.id,
            name: wmFirmData.name,
            type: firmTypeMapping[wmFirmData.firm_type],
            subdomain: wmFirmData.subdomain,
            logo: wmFirmData.logo_status,
            master_user_email: wmFirmData.master_user_email,
          };

          setWmFirmInfo(wmFirmObject);
        }
      } catch (err) {
        setWmFirmInfo(null);
      }
      setLoading(false);
    }
    getWMFirmInformation();
  }, [refresh]);

  return { wmFirmInfo };
};
