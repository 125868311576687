import React, { useState } from "react";
import { useNavigate } from "react-router";
import "./Styles/Styles.scss";
import SearchBar from "../../../../../Components/SearchBar/SearchBar";
import MuiButton from "../../../../../Components/Buttons/MuiButton/MuiButton";
import UserTable from "../UserTable/UserTable";
import useMasterOpsFirmUserSearch from "../../../../../APIs/useMasterOpsFirmUserSearch";
import { DataGridPro } from "@mui/x-data-grid-pro";

function ViewUsers({ setShowAddFirmUser }) {
  const [firmUserQuery, setFirmUserQuery] = useState("");
  const [firmUserData, setFirmUserData] = useState([]);
  const [firmUserLoading, setFirmUserLoading] = useState(true);
  const { firmUsers } = useMasterOpsFirmUserSearch(
    firmUserQuery,
    setFirmUserLoading
  );
  const navigate = useNavigate();

  const columns = () => [
    {
      dataField: "id",
      hidden: true,
    },
    {
      dataField: "name",
      text: "Name",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "email",
      text: "Email",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
      headerStyle: { width: "33%" },
    },
    {
      dataField: "phoneNumber",
      text: "Phone Number",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
    {
      dataField: "role",
      text: "Role",
      classes: "body-text-md-regular align-middle",
      headerClasses: "start-padding body-text-md-bold",
    },
  ];

  const newCols = [
    {
      field: "id",
      headerName: "ID",
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
    {
      field: "role",
      headerName: "Role",
      headerClassName: "stylish-header",
      editable: false,
      flex: 1,
      filterable: false,
      hideSortIcons: true,
      sortable: false,
      hideable: false,
      pinnable: false,
    },
  ];

  return (
    <React.Fragment>
      <div className="row g-0 w-100 mb-3">
        <h5>Manage Users</h5>
      </div>
      <div className="row g-0 w-100 mb-4 align-items-center">
        <div style={{ width: "85%" }}>
          <SearchBar
            setValue={setFirmUserQuery}
            placeholder={"Search by full name, etc"}
          />
        </div>
        <div
          className="ms-auto me-0 justify-self-end justify-content-end d-flex"
          style={{ maxWidth: "120px" }}
        >
          <MuiButton
            buttonText={"Add User"}
            onClick={() => setShowAddFirmUser(true)}
            size={"large"}
          />
        </div>
      </div>
      <div
        className="row g-0 w-100"
        style={{
          backgroundColor: "white",
          borderRadius: "6px",
        }}
      >
        {/* <UserTable data={firmUsers} columns={columns} /> */}
        <DataGridPro
          sx={{
            height: "400px",
            ".MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "&.MuiDataGrid-root": {
              borderRadius: "6px",
            },
          }}
          rows={firmUsers}
          columns={newCols}
          loading={firmUserLoading}
          rowHeight={54}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
        />
      </div>
    </React.Fragment>
  );
}
export default ViewUsers;
