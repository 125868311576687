import React, { useState, useEffect, useContext } from "react";
import {
    GetFundSubscriptionInfo,
    GetInvestorFundInvestment,
} from "../../../Adapters/FundActions";
import { useParams, useNavigate } from "react-router-dom";
import { getFirmUser, clientDetails } from "../../../APIs/WealthManagementAPI";
import { createBulkInvestments, getFundInvestmentInformation, getUninvestedClients } from "../../../APIs/ConfirmInvestmentAPI";
import Spinner1 from "../../../Components/Spinners/Spinner1";
import EnhancedKYC from "../../../Components/EnhancedKYC/EnhancedKYC";

interface ConfirmInvestmentsProps {
    user: any;
}

const ConfirmInvestments: React.FC<ConfirmInvestmentsProps> = ({ user }) => {
    const navigate = useNavigate();
    const [selectedTab, setSelectedTab] = useState("Investment");
    const [investmentAmount, setInvestmentAmount] = useState<string>("");
    const [submitError, setSubmitError] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(true);
    const [notVerified, setNotVerified] = useState<boolean>(false);

    const { id } = useParams();

    const [fundInfo, setFundInfo] = useState<any>({
        name: "Placeholder",
        minimumInvestment: 5000, // Minimum investment amount in USD
        id: ''
    });

    useEffect(
        () => { setSubmitError("") }, [investmentAmount]
    ); // Clear error

    useEffect(() => {

        async function getFundInvestmentInformationObject() {
            try {
                const response = await getFundInvestmentInformation(id);
                const fundInvestmentInformationData = response.data;
                setFundInfo((prev: any) => ({
                    ...prev, id: fundInvestmentInformationData['id'],
                    remaining: fundInvestmentInformationData.max_fund_size - fundInvestmentInformationData.committed_allocation_amount,
                    minimumInvestment: fundInvestmentInformationData['minimum_allocation_amount'],
                    name: fundInvestmentInformationData['name']
                }))
            } catch (err) {
                if (process.env.REACT_APP_DEBUG === "1") {
                    console.log("There is an error.");
                    console.error(err);
                }
            }
        }

        getFundInvestmentInformationObject();


    }, []);

    useEffect(() => {
        async function getUninvestedClientsData() {
            user.clientId === null && navigate("/account/add");
            const uninvestedClients = await getUninvestedClients(fundInfo.id);
            const invested = uninvestedClients.data.filter((e: any) => e.id === user.clientId).length === 0;
            if (invested) {
                const response = await clientDetails(user.clientId);
                if (response.data && response.data.table_data) {
                    const stage = (response.data.table_data.find((e: any) => e.fund_id === fundInfo.id)?.status || setSelectedTab("Investment"));
                    if (stage === "P") {
                        setSelectedTab("Subdocs"); setNotVerified(true);
                    }
                    else if (stage === "PD") {
                        setSelectedTab("Subdocs");
                    }
                    else if (stage === "PF") {
                        setSelectedTab("Wire Instructions")
                    }
                }
                else {
                    setSelectedTab("Investment"); setNotVerified(true);
                };
                setLoading(false);
            }
        }

        fundInfo && fundInfo.id && user && getUninvestedClientsData();

    }, [fundInfo, user]);

    const tabs = [
        { label: "Investment" },
        { label: "Document Collection" },
        { label: "Subdocs" },
        { label: "Wire Instructions" },
        { label: "Funded" },
    ];

    const submitInvestments = async () => {
        setLoading(true);

        const amount = parseInt(investmentAmount.replace(/[^0-9]/g, ''));

        if (isNaN(amount)) {
            setSubmitError("Amount is required.")
        }
        else if (amount < fundInfo.minimumInvestment) {
            console.log("HERE")
            setSubmitError("Amount is less than minimum.");
        }
        else if (amount > fundInfo.remaining) {
            setSubmitError("Amount is greater than remaining allocation.")
        }

        else {

            const payloadObject = {
                fund_id: fundInfo.id,
                investments: [{
                    "id": user.clientId,
                    "proposed_investment_amount": amount
                }],
            };

            try {
                let requestResponse = await createBulkInvestments(payloadObject);
                // Return updated KYC Requirements?
                setSelectedTab("Document Collection");
            } catch (err) {
                setSubmitError('Error.');
            }
        };
        setLoading(false);
    };

    // Utility function to format numbers as USD currency inside the input
    const formatCurrency = (amount: string | number) => {
        if (!amount) return '';
        const value = typeof amount === 'string' ? parseFloat(amount.replace(/,/g, '')) : amount;
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
        }).format(value);
    };

    const renderTabContent = () => {

        // Handle input change with currency formatting
        const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const rawValue = e.target.value.replace(/[^\d.]/g, ''); // Strip out any non-numeric characters except periods
            if (!rawValue) {
                setInvestmentAmount(""); // If input is cleared
                return;
            }
            const numericValue = parseFloat(rawValue);
            if (!isNaN(numericValue)) {
                setInvestmentAmount(formatCurrency(numericValue)); // Set the formatted value
            }
        };
        if (loading) {
            <Spinner1 />
        }
        else if (selectedTab === "Investment") {
            return (
                <div className="tw-p-4 tw-space-y-6" style={{ "width": "68%" }}> {/* Vertical spacing */}
                    {/* Confirm Investment Header */}
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Confirm Investment</h1>

                    {/* Fund Info Box */}
                    <div className="tw-bg-gray-100 tw-px-4 tw-py-2 tw-rounded-md tw-shadow-md" style={{ "width": "50%" }}>
                        <h3 className="tw-text-lg tw-font-semibold tw-mb-1">{fundInfo.name}</h3>
                        <p className="tw-text-sm tw-text-gray-700">
                            Minimum Investment: {formatCurrency(fundInfo.minimumInvestment)}<br />
                            Remaining Allocation: {formatCurrency(fundInfo.remaining)}
                        </p>


                    </div>

                    {/* Investment Amount Input */}
                    <div>
                        <h2 className="tw-text-xl tw-font-semibold tw-mb-4">Investment Amount</h2>
                        <div className="tw-space-y-4">
                            <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                Enter the amount you wish to invest:
                            </label>

                            {/* Input with formatted currency */}
                            <div className="tw-relative tw-mt-1">
                                <input
                                    type="text"
                                    id="amount"
                                    value={investmentAmount}
                                    onChange={handleAmountChange}
                                    className="tw-block tw-w-full tw-pl-3 tw-pr-3 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-shadow-sm focus:tw-outline-none focus:tw-ring-indigo-500 focus:tw-border-indigo-500 tw-sm:tw-text-sm"
                                    placeholder="$0.00"
                                />
                            </div>
                            {submitError && <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                                {submitError}
                            </label>}
                            <button
                                type="submit"
                                onClick={submitInvestments}
                                className="tw-mt-4 tw-bg-indigo-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-md hover:tw-bg-indigo-700"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            );
        }
        else if (selectedTab === "Document Collection") {
            return (
                <div className="tw-p-4 tw-space-y-6">
                    <h1 className="tw-text-2xl tw-font-bold tw-mb-6">KYC Document Collection</h1>
                    <div className="tw-space-y-4">
                        <EnhancedKYC />
                        <button
                            className="tw-px-4 tw-py-2 tw-bg-blue-500 tw-text-white tw-rounded-md hover:tw-bg-blue-600"
                            onClick={() => setSelectedTab("Subdocs")}
                        >
                            Submit later on Tasks page
                        </button>
                    </div></div>);
        }

        else if (selectedTab === "Subdocs") {
            return (<div className="tw-p-4 tw-space-y-6">
                <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Subdocs</h1>
                <div className="tw-space-y-4">
                    <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                        {<>Congratulations, you have successfully invested in this opportunity. Subscription documents will be sent from noreply@mail.hellosign.com</>}
                    </label></div>
            </div>);
        }
        else if (selectedTab === "Wire Instructions") {
            return (<div className="tw-p-4 tw-space-y-6">
                <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Confirmation</h1>
                <div className="tw-space-y-4">
                    <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                        Wire instructions will be shared through email.
                    </label></div>
            </div>);
        }
        else if (selectedTab === "Funded") {
            return (<div className="tw-p-4 tw-space-y-6">
                <h1 className="tw-text-2xl tw-font-bold tw-mb-6">Funded</h1>
                <div className="tw-space-y-4">
                    <label htmlFor="amount" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
                        Initial capital call is funded.
                    </label></div>
            </div>);
        }
    };


    return (
        <div className="tw-flex container-box">
            <div className="tw-w-1/4 tw-bg-gray-100 tw-p-4">
                <div className="tw-space-y-4">
                    {tabs.map((tab) => (
                        <div
                            key={tab.label}
                            className={`tw-flex tw-items-center tw-px-3 tw-py-2 tw-rounded-md ${selectedTab === tab.label ? "tw-bg-indigo-600 tw-text-white" : "tw-bg-gray-200 tw-text-gray-600"
                                }`}
                        >
                            <span className="tw-text-lg tw-font-medium">{tab.label}</span>
                        </div>
                    ))}
                </div>
            </div>

            {/* Content Section */}
            <div className="tw-w-3/4 tw-p-4">{renderTabContent()}</div>
        </div>
    );
};

export default ConfirmInvestments;